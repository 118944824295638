<template lang="pug">
    h1.mb-10 
        |{{label}}
        slot
</template>

<script>
export default {
  name: "PageTitle",
  props: [
    'label'
  ]
}
</script>